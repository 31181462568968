<template>
  <div class="w1200">
    <div class="list">
      <div class="list_item flex_box" v-for="item in newList" :key='item.id' @click="gonewDetails(item)">
        <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='fill' class="img" style="width: 240px; height: 150px"></el-image>
          <div class="flex1">
            <p class="time">
              <span>{{item.createTime}}</span>
            </p>
            <h3 class="title">{{item.newsTitle}}</h3>
            <p class="desc" v-html="item.subtitle"></p>
          </div>
      </div>
    </div>
    <pagination ref='pagination' :total='total' @currentChange='currentChange' :pageSize='params.pageSize'>

    </pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total:0, // 总条数
      params:{
        pageNum:1,
        pageSize:5,
        newsType:1
      },// 参数
      newList:[],// 列表数据
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    // 获取列表
    getList() {
      this.$api.getNewsListByPage(this.params).then(res=>{
        this.newList = res.rows
        this.total = res.total
      })
    },
    // 当前页改变
    currentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    // 打开详情
    gonewDetails(item) {
      this.$router.push({
        path:'/newDetails/'+item.id,
        query:{type:3}
      })
    },
  }
}
</script>

<style lang='stylus' scoped>
@import '~@/assets/stylus/variable'
  .list_item
    padding 40px 40px 30px 0
    border-bottom: 1px dashed #bbb
    cursor pointer
    &:hover
      .title
        color $theme-color
      .iconfont
        background: $theme-color
        border-color $theme-color
        color #fff
    .img
      margin-right 36px
    .time
      font-size 16px
      color #838383
    .title
      font-size 22px
      color #333333
      margin 15px 0
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
    .desc
      font-size 16px
      color #666666
      line-height: 30px
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
</style>